import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import Prices from "../../components/Prices/Prices";

const prices = () => {
	return (
		<MainLayout lang="en" title="prices">
			<Prices />
		</MainLayout>
	);
};

export default prices;
